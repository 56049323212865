import React from 'react'

export default function Gotop_section() {
  return (
    <>
  
  <div className="go-top">
    <i className="fas fa-chevron-up" />
    <i className="fas fa-chevron-up" />
  </div>
  
  
  </>
  )
}
